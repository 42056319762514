import {
  Box,
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Grow,
} from "@mui/material";
import React, { useRef } from "react";
import {
  Alexander,
  Background,
  Benjamin,
  David,
  Dennis,
  Emmanuel,
  Eric,
  EricKofi,
  Francis,
  Gameli,
  Gyesi,
  HistoryBanner,
  Ignatius,
  Jennifer,
  John,
  KennethKwame,
  KennethOwusu,
  Lawrence,
  Mark,
  Michael,
  Mills,
  MissionImg,
  Moses,
  Paul,
  President,
  Prince,
  Reuben,
  Robert,
  Samuel,
  SamuelAmoah,
  SamuelKwabena,
  Sheila,
  SolomonAsante,
  SolomonKwashie,
  Theophilus,
} from "../../assets";
import {
  CustomStepper,
  IconBtn,
  Newsletter,
  Title,
  Stepper,
} from "../../components";
import {
  composition,
  mission_vision,
  past_presidents,
  steps,
} from "../../constants";
import Image from "mui-image";
import { ExecutiveCard } from "../../components/executiveSlider";
import { useElementOnScreen } from "../../hooks/useElementOnScreen";

const boxStyle = {
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
};

const council = [
  {
    name: "DR. ROBERT ADEDZE-KPODO",
    position: "Vice President",
    image: Robert,
  },
  {
    name: "SOLOMON D.Y KWASHIE",
    position: "General Secretary",
    image: SolomonKwashie,
  },
  {
    name: "JENNIFER ADOLEY BAFFOE",
    position: "Financial Secretary",
    image: Jennifer,
  },
  {
    name: "DR. IGNATIUS AWINIBUNO",
    position: "Imm. Past President",
    image: Ignatius,
  },
  {
    name: "MICHAEL AMO OMARI",
    position: "Imm. Past Gen. Secretary",
    image: Michael,
  },
  {
    name: "W.A.MILLS PAPPOE",
    position: "Clinical Labs Unit-GHS",
    image: Mills,
  },
  {
    name: "DR. SAMUEL ESSIEN-BAIDOO",
    position: "Academia Represenative",
    image: Samuel,
  },
  { name: "DR. ALEXANDER MARTIN ODOOM", position: "APML", image: Alexander },
  {
    name: "MRS. SHEILA ALLOTEY",
    position: "Women’s Wing President",
    image: Sheila,
  },
  {
    name: "DR. DENNIS ADU-GYASI",
    position: "Public Relations Officer",
    image: Dennis,
  },
  {
    name: "HON. DR. PRINCE SODOKE AMUZU",
    position: "Board Member, Ahpc",
    image: Prince,
  },
  {
    name: "FRANCIS ABEKU USSHER",
    position: "Chairman, Psc- Ahpc",
    image: Francis,
  },
  {
    name: "LAWRENCE YINE",
    position: "Chairman, Melpwu",
    image: Lawrence,
  },
  {
    name: "DR. BENJAMIN MENSAH TETTEH",
    position: "Wapcmls - Gh Chapter Chairman",
    image: Benjamin,
  },
  {
    name: "DR. DAVID SEBBIE SACKEY",
    position: "Smlm - G Board Chairman",
    image: David,
  },
];

const regional_executives = [
  { name: "JOHN TOSENU MENSAH", position: "Greater Accra", image: John },
  {
    name: "SAMUEL AMOAH",
    position: "Central Region",
    image: SamuelAmoah,
  },
  {
    name: "SAMUEL KWABENA APPIAH",
    position: "Eastern Region",
    image: SamuelKwabena,
  },
  {
    name: "EMMANUEL APELIKUBA ADONGO",
    position: "Upper East Region",
    image: Emmanuel,
  },
  {
    name: "MARK DANQUAH",
    position: "Bono Region",
    image: Mark,
  },
  {
    name: "MOSES KOFI WOLI",
    position: "Bono East Region",
    image: Moses,
  },
  {
    name: "PAUL NTIAMOAH",
    position: "Ahafo Region",
    image: Paul,
  },
  {
    name: "SOLOMON ASANTE-SEFA",
    position: "Western Region",
    image: SolomonAsante,
  },
  {
    name: "KENNETH KWAME KYE-AMOAH",
    position: "Western North -Region",
    image: KennethKwame,
  },
  {
    name: "REUBEN SEYRAM ADEKPLORVI",
    position: "Oti Region",
    image: Reuben,
  },
  {
    name: "KENNETH OWUSU AGYEMANG",
    position: "Volta Region",
    image: KennethOwusu,
  },
  {
    name: "DR. ERIC MISHIO BAWA",
    position: "North East Region",
    image: Eric,
  },
  {
    name: "THEOPHILUS QUAYE",
    position: "Savanna Region",
    image: Theophilus,
  },
  {
    name: "GAMELI EMMANUEL ADZRAKU",
    position: "Upper West Region",
    image: Gameli,
  },
  {
    name: "ERIC KOFI AIDOO",
    position: "Ashanti Region",
    image: EricKofi,
  },
  {
    name: "GYESI RAZAK ISSAHAKU",
    position: "Northern Region",
    image: Gyesi,
  },
];

const FormationContent = () => {
  return (
    <Box sx={boxStyle}>
      <Box
        sx={{
          px: { xs: "15px", md: "80px" },
          pt: { xs: 6, md: 12 },
        }}
      >
        <Title
          title={"History"}
          subTitle={
            "The Ghana Association of Medical Laboratory Scientists (GAMLS) is the sole professional body for all Medical Laboratory Professionals in Ghana registered in accordance with the Professional Bodies Registration Decree, NRCD 143 of 1973 with registration number PB 2 on 25th July 1973"
          }
          underline
        />
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${HistoryBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
          mt: { xs: 2, md: 6 },
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            background:
              "transparent linear-gradient(95deg, #F3081340 0%, #7A040AFA 100%) 0% 0% no-repeat padding-box",
            p: { xs: 2, md: 12 },
          }}
        >
          <Box flex={{ xs: 0.3, md: 1 }}></Box>
          <Box flex={1} sx={{ color: "white", px: 6 }}>
            <Typography variant={"body1"}>
              {
                "The Ghana Association of Medical Laboratory Scientists (GAMLS) is the sole professional body for all Medical Laboratory Professionals in Ghana registered in accordance with the Professional Bodies Registration Decree, NRCD 143 of 1973 with registration number PB 2 on 25th July 1973"
              }
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          px: { xs: "15px", md: "80px" },
          pt: { xs: 6, md: 12 },
        }}
      >
        <Title title={"Our Past Presidents"} underline />

        <Stack mt={8}>
          <Stepper data={past_presidents} />
        </Stack>
      </Box>

      <Box
        sx={{
          px: { xs: "15px", md: "80px" },
          py: 12,
        }}
      >
        <Newsletter />
      </Box>
    </Box>
  );
};

const MissionContent = () => {
  return (
    <Box sx={boxStyle}>
      <Stack
        sx={{
          px: { xs: "15px", md: "140px" },
          pt: 6,
        }}
        mb={{ xs: 8, md: 16 }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          px={{ xs: "15px", md: 24 }}
          mb={{ xs: 6, md: 12 }}
        >
          <Stack>
            <Title
              title={"Mission"}
              underline
              xSpace
              subTitle={mission_vision.mission}
            />
          </Stack>

          <Image src={MissionImg} />
        </Stack>

        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          px={{ xs: "15px", md: 24 }}
          mb={{ xs: 6, md: 12 }}
          spacing={{ xs: 0, md: 6 }}
        >
          <Image src={MissionImg} />
          <Stack
            alignItems={{ xs: "flex-start", md: "flex-end" }}
            sx={{ textAlign: { xs: "inherit", md: "right" } }}
          >
            <Title
              title={"Vision"}
              underline
              xSpace
              subTitle={mission_vision.vision}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} spacing={8} my={6}>
          <Card
            sx={{
              backgroundColor: "#EAEAEA",
              display: "flex",
              flex: 1,
              borderRadius: 5,
              p: 3,
            }}
          >
            <CardContent>
              <Title
                title={"Objectives"}
                underline
                xSpace
                subTitle={
                  "The objects for which the Association is formed are:"
                }
              />
              <CustomStepper steps={steps} />
            </CardContent>
          </Card>

          <Card
            sx={{
              backgroundColor: "#EAEAEA",
              display: "flex",
              flex: 1,
              borderRadius: 5,
              p: 3,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <CardContent>
              <Title
                title={"Composition of GAMLS"}
                underline
                xSpace
                subTitle={"The Association is Composed by the:"}
              />
              <ul className={"mission"}>
                {composition.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Stack>
        <Newsletter />
      </Stack>
    </Box>
  );
};

const ExecutiveContent = () => {
  const ref1 = useRef(null);
  const onScreen1 = useElementOnScreen(ref1);
  const ref2 = useRef(null);
  const onScreen2 = useElementOnScreen(ref2);
  return (
    <Box sx={boxStyle}>
      <Box
        sx={{
          px: { xs: "15px", md: "80px" },
        }}
      >
        <Stack spacing={5}>
          <Title
            subTitle={
              "The Association is led by a dedicated Team led by a team of dedicated Medical Laboratory Scientists. Their mandate is for a 2-yr period after which a new election is held."
            }
          />
          <Box>
            <Title title={"The Executive Council"} underline xSpace />
          </Box>
        </Stack>

        <Card sx={{ display: "flex", borderRadius: "20px", mt: 6 }}>
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <CardMedia
              component="img"
              sx={{ width: { xs: "100%", md: 500 } }}
              image={President}
              alt="Image of the president"
            />
            <CardContent>
              <Stack
                justifyContent={"space-between"}
                flexGrow={1}
                sx={{ height: "100%", p: { xs: "13px 20px", md: "88px 40px" } }}
              >
                <Stack>
                  <Title title={"From our president"} />

                  <Box
                    flexGrow={1}
                    sx={{
                      borderLeft: "1px dashed #F30813",
                      pl: { xs: 2, md: 2 },
                      mb: 6,
                    }}
                  >
                    <Typography>
                      {
                        "It is my great pleasure to communicate with you through our renewed online presence. I feel very honored, but also highly motivated to serve this noble profession especially as your president. I am particularly proud of the progress that this association has chalked in its successive journeys through the tireless efforts of leadership of this Association both past and present. The basic principle of my team is hinged on the objects of the Association; brotherliness, professionalism and best practices that has guided the forward march of the association. Looking to the future with optimism, we are preparing for significant growth and development Thank you for your support so far, I assure you that for all of you we will continue to show leadership and push the interest of the profession on all fronts to ensure that we heed to the tenets of SERVICE to HUMANITY for the past 40 years. Yours faithfully,"
                      }
                    </Typography>
                  </Box>
                </Stack>

                <Typography variant={"h6"} fontWeight={"bold"}>
                  {"Dr. Abudu Rahmani"}
                </Typography>
                <Stack>
                  <Typography>{"You can also reach out to him on:"}</Typography>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 0, md: 6 }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                  >
                    <Typography fontWeight={"bold"}>
                      {"Email: "}
                      <Typography component={"span"}>
                        {"examplename@gamls.org"}
                      </Typography>
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <IconBtn icon={"facebook"} />
                      <IconBtn icon={"instagram"} />
                      <IconBtn icon={"twitter"} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Box>
        </Card>

        <Grid container columnSpacing={4} rowSpacing={8} my={6} ref={ref1}>
          {council.map((executive, i) => {
            const timeout = i + 1 * 1000;
            return (
              <Grid item key={i} xs={12} sm={5} md={4} lg={3}>
                <Grow
                  in={onScreen1}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(onScreen1 ? { timeout: timeout } : {})}
                >
                  <Box>
                    <ExecutiveCard data={executive} />
                  </Box>
                </Grow>
              </Grid>
            );
          })}
        </Grid>

        <Stack mb={12} spacing={12} ref={ref2}>
          <Box>
            <Title
              title={"Regional Executive Council (Chairmen)"}
              underline
              xSpace
            />
            <Grid container columnSpacing={4} rowSpacing={8}>
              {regional_executives.map((executive, i) => {
                const timeout = i + 1 * 1000;
                return (
                  <Grid item xs={12} md={3} key={i} sm={4} lg={3}>
                    <Grow
                      in={onScreen2}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(onScreen2 ? { timeout: timeout } : {})}
                    >
                      <Box>
                        <ExecutiveCard data={executive} />
                      </Box>
                    </Grow>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Newsletter />
        </Stack>
      </Box>
    </Box>
  );
};

export { FormationContent, MissionContent, ExecutiveContent };
