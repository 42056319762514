import { SvgIcon } from "@mui/material";
import { ReactComponent as LockIcon } from '../icons/ic_lock.svg';

function Lock(props){
    return  <SvgIcon
    {...props}
    component={LockIcon}
    inheritViewBox
  />
}

export default Lock