import {
  CssBaseline,
  InputAdornment,
  TextField,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { IconBtn, Newsletter, Title } from "../../components";
import { MapBg , EmailOutlined,
  AccountCircleOutlined,
  CallOutlinedIcon,} from "../../assets";
import { app_constants } from "../../constants";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";

const StyledTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
    opacity: 22,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#F30813",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& input.MuiInput-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    borderColor: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: "white",
    borderColor: "white",
  },
}));

const MapBox = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback((link) => (window.location.href = link), []);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_uydtm27",
        "template_7euxau5",
        form.current,
        "vozpNfR4d1KEeLf-b"
      )
      .then(
        (result) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Your mail has been sent successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        },
        (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Email sending fail. Kindly retry",
          });
        }
      );
  };
  return (
    <Box
      flexGrow={1}
      sx={{
        width: "100%",
        height: { md: 900 },
        p: { xs: "60px 15px", md: "120px 140px" },
        backgroundImage: `url(${MapBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 8, md: 32 }}
        sx={{ color: "white" }}
      >
        <Box flex={1}>
          <Title
            title={"We’d Love to Hear From You"}
            subTitle={
              "You can send us a message and expect a response in no time"
            }
          />

          <Box
            component={"form"}
            onSubmit={sendEmail}
            ref={form}
            sx={{
              "& .MuiTextField-root": { m: 1 },
              mt: 3,
            }}
            flex={1}
          >
            <Stack spacing={6}>
              <StyledTextField
                variant="standard"
                id="standard-text"
                placeholder={"Your name"}
                name={"user_name"}
                type={"text"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      sx={{ fontSize: "12px", color: "white", opacity: 0.3 }}
                    >
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                variant="standard"
                id="standard-text"
                placeholder={"Your email"}
                name={"user_email"}
                type={"email"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      sx={{ fontSize: "12px", color: "white", opacity: 0.3 }}
                    >
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                variant="standard"
                id="standard-text"
                placeholder={"Your number"}
                name={"user_number"}
                type={"tel"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      sx={{ fontSize: "12px", color: "white", opacity: 0.3 }}
                    >
                      <CallOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                variant="outlined"
                id="standard-text"
                name={"message"}
                multiline
                placeholder={"Type your message here..."}
                type={"text"}
                rows={5}
              />
              <LoadingButton
                type={"submit"}
                variant={"contained"}
                loading={loading}
              >
                {"Send message"}
              </LoadingButton>
            </Stack>
          </Box>
        </Box>
        <Box flex={1} sx={{ pt: { xs: 0, md: 5 } }}>
          <Title title={"Get In-Touch"} subTitle={"You can reach us here"} />
          <Typography gutterBottom>{app_constants.mail}</Typography>

          <Stack spacing={3} sx={{ my: 3 }}>
            {[
              {
                header: "Address",
                sub1: "Near School of Medicine and Dentistry-UG",
                sub2: "Korle-Bu Hospital - Accra",
                link1: "https://goo.gl/maps/j2T3Mtgh87q88ecZ6",
                link2: "https://goo.gl/maps/j2T3Mtgh87q88ecZ6",
              },
              {
                header: "Mail",
                sub1: app_constants.mail,
                link1:
                  "mailto:info@gamls.org?subject=Subject&body=message%20goes%20here",
              },
              {
                header: "Mobile",
                sub1: "+233 (0) 55 852 1561",
                sub2: "+233 (0) 30 268 0011",
                link1: "tel:+233 (0) 55 852 1561",
                link2: "tel:+233 (0) 30 268 0011",
              },
            ].map((item) => {
              return (
                <Stack key={item.header}>
                  <Typography fontWeight={"bold"} variant={"h6"} gutterBottom>
                    {item.header}
                  </Typography>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    gutterBottom
                    onClick={() => handleClick(item.link1)}
                  >
                    {item.sub1}
                  </Typography>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    gutterBottom
                    onClick={() => handleClick(item.link2)}
                  >
                    {item.sub2}
                  </Typography>
                </Stack>
              );
            })}
            <Stack direction={"row"} spacing={2}>
              <IconBtn icon={"facebook"} />
              <IconBtn icon={"instagram"} />
              <IconBtn icon={"twitter"} />
              <Box flex={0.7}></Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const Content = () => {
  return (
    <Box
      sx={{
        px: { xs: "15px", md: "140px" },
        pt: { xs: 8, md: "82px" },
        mb: { xs: 8, mb: 12 },
      }}
    >
      <Newsletter />
    </Box>
  );
};

const Contact = () => {
  return (
    <>
      <CssBaseline />
      <MapBox />
      <Content />
    </>
  );
};
export default Contact;
