import { Container } from "@mui/material";
import Image from "mui-image";
import { Notfound } from "../../assets";

const NotFound = () => {
  return (
    <Container sx={{ p: { xs: 6, md: 12 } }}>
      <Image width={"100%"} height={"100%"} src={Notfound} />
    </Container>
  );
};

export default NotFound;
