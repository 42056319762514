export class BannerModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.banner_text = obj.banner_text != null ? obj.banner_text : "";
    this.img_url = obj.img_url != null ? obj.img_url : "";
    this.btn_text = obj.btn_text != null ? obj.btn_text : "";
    this.btn_link = obj.btn_link != null ? obj.btn_link : "";
    this.date = obj.date != null ? obj.date : "";
  }
}

export const bannerConverter = {
  toFirestore: (banner) => {
    return {
      id: banner.id,
      banner_text: banner.banner_text,
      img_url: banner.img_url,
      btn_text: banner.btn_text,
      btn_link: banner.btn_link,
      date: banner.date,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new BannerModel({
      id: data.id,
      banner_text: data.banner_text,
      img_url: data.img_url,
      btn_text: data.btn_text,
      btn_link: data.btn_link,
      date: data.date.seconds
        ? new Date(data.date.seconds * 1000).toLocaleDateString()
        : data.date,
    });
  },
};
