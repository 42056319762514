import { Box } from "@mui/material";
import InputText from "../../../components/form/inputText";
import PasswordStrengthBar from "react-password-strength-bar";

export const Credential = ({formik}) => {

  return (
    <>
      <InputText
        placeholder={"Email"}
        value={formik.values.email}
        onChange={formik.handleChange}
        helperText={formik.errors.email}
        name={"email"}
        error={formik.touched.email && Boolean(formik.errors.email)}
      />
      <Box>
        <InputText
          placeholder={"Password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          helperText={formik.errors.password}
          name={"password"}
          type={"password"}
          error={formik.touched.password && Boolean(formik.errors.password)}
        />
        {formik.values.password ? (
          <PasswordStrengthBar password={formik.values.password} />
        ) : null}
      </Box>
      <InputText
        placeholder={"Re-type Password"}
        value={formik.values.retypePassword}
        onChange={formik.handleChange}
        helperText={formik.errors.retypePassword}
        name={"retypePassword"}
        type={"password"}
        error={
          formik.touched.retypePassword && Boolean(formik.errors.retypePassword)
        }
      />
    </>
  );
};
