import { CssBaseline } from "@mui/material";
import { Content, LearningBanner } from "./learningElements";

const Learning = () => {
  return (
    <>
      <CssBaseline />
      <LearningBanner />
      <Content />
    </>
  );
};
export default Learning;
