import { Content } from "./homeElements";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateBanner } from "../../data/banners/bannerActions";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBanner());
  }, [dispatch]);

  return <Content />;
};
export default Home;
