import { useSelector } from "react-redux";

export const useGreeting = () => {
  const { user } = useSelector((state) => state.auth);

  const memberName = user?.firstName ? user?.firstName : "Member";

  let timeNow = new Date().getHours();
  let greeting =
    timeNow >= 5 && timeNow < 12
      ? "Good Morning"
      : timeNow >= 12 && timeNow < 18
      ? "Good Afternoon"
      : "Good Evening";
  return `${greeting}, ${memberName}`;
};
