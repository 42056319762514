import { useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchCoursesByCategory,
  fetchLessonsByCourse,
} from "../../data/learning/learningAction";
import { LessonModel } from "../../data/models/lessonModel";
import { postUserLearning } from "../../data/auth/authAction";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../data/firebase";

const LearningHook = () => {
  const { id } = useParams();
  const { data } = useSelector((state) => state.categories);
  const { courses } = useSelector((state) => state.courses);
  const [user] = useAuthState(auth);
  const { lessons, loading: lessonsLoading } = useSelector(
    (state) => state.lessons
  );
  const { name: categoryName } = data.find((x) => x.id === id);
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const activeLesson = lessons.length === 0 ? new LessonModel() : lessons[0];
  const [courseCompleted, setCourseCompleted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCoursesByCategory(categoryName));
  }, [dispatch, categoryName]);

  useEffect(() => {
    if (courses.length !== 0) {
      dispatch(fetchLessonsByCourse(courses[0]?.name));
    }
  }, [courses, dispatch]);

  useEffect(() => {}, [values]);

  const matches = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleChange = useCallback(
    (id, event) => {
      const value = {
        id: id,
        value: event.target.value,
        error: false,
        helperText: "",
      };
      const existingIndex = values.findIndex((obj) => obj.id === value.id);
      if (existingIndex === -1) {
        setValues([...values, value]);
      } else {
        const updatedState = [...values];
        updatedState[existingIndex] = value;
        setValues(updatedState);
      }
    },
    [values]
  );

  const handleCourseClick = useCallback(
    (courseName) => {
      dispatch(fetchLessonsByCourse(courseName));
      if (!matches) handleClick();
    },
    [matches, handleClick, dispatch]
  );

  const handleSubmit = useCallback(
    (key, event) => {
      event.preventDefault();
      const { id, value } = values.find((x) => x.id === key);
      const question = activeLesson?.questions[id];
      const isCorrect = value.toLowerCase() === question.correctAnswer;

      let newValues = values?.map((obj) =>
        obj.id === id
          ? {
              ...obj,
              error: !isCorrect,
              helperText: isCorrect ? "Correct!" : "Wrong answer, please retry",
            }
          : obj
      );
      setValues(newValues);
    },
    [activeLesson?.questions, values]
  );

  const handleNext = useCallback(() => {
    const shouldGoNext = values?.map((obj, idx) =>
      obj.helperText !== "Correct!" ? idx : null
    );
    if (shouldGoNext.length === activeLesson?.questions?.length) {
      //setCourseCompleted(true);
      postUserLearning(user.uid, { categoryId: id, values: values });
    } else {
      alert("cannot go next");
    }
  }, [values, activeLesson?.questions?.length, user.uid, id]);

  const handlePrevious = () => {
    setCourseCompleted(false);
  };
  const handleDone = () => {
    navigate("/learning");
  };

  return {
    values,
    handleChange,
    handleNext,
    handleSubmit,
    open,
    handleClick,
    courses,
    activeLesson,
    handleCourseClick,
    courseCompleted,
    handlePrevious,
    handleDone,
    lessonsLoading,
  };
};

export default LearningHook;
