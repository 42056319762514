import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ElectionImg, AccountCircleOutlined,LockOutlined } from "../../assets";
import { routePaths } from "../../constants";

const ElectionLogin = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        py: 6,
        height: { md: "49rem" },
      }}
    >
      <Typography variant={"h5"} fontWeight={"bold"}>
        {"Welcome to GAMLS Elections"}
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 8, md: 16 }}
        px={{ xs: "15px", md: 24 }}
      >
        <Box>
          <Image src={ElectionImg} />
        </Box>

        <Card
          sx={{
            borderRadius: "20px",
            maxWidth: { xs: "100%", md: "35%" },
          }}
        >
          <CardContent>
            <Typography variant={"body1"}>
              {
                "For your exclusiveness, you must be logged in to view vote your preferred candidate. If you can’t login kindly contact the "
              }
              <Typography
                variant={"body1"}
                component={"span"}
                color={"primary"}
                sx={{ cursor: "pointer" }}
              >
                {"Admin"}
              </Typography>
              {"."}
            </Typography>

            <Stack spacing={6} my={6}>
              <TextField
                variant={"standard"}
                label={"Member ID"}
                type={"text"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      sx={{ fontSize: "12px" }}
                    >
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                variant={"standard"}
                label={"Member Password"}
                type={"password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position={"start"}
                      sx={{ fontSize: "12px" }}
                    >
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="text"
                sx={{
                  width: "fit-content",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                {"Forgot Password"}
              </Button>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  width: "fit-content",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  borderRadius: 5,
                  px: 6,
                }}
                type={"submit"}
                onClick={() =>
                  navigate(routePaths.elections, {
                    state: { isVerified: true },
                  })
                }
              >
                {"Login"}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};
export default ElectionLogin;
