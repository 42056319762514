import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { GamlsLogoWhite } from "../assets";
import { routePaths } from "../constants";
import { useNavigate } from "react-router-dom";
import IconBtn from "./iconButton";

const openEkad = () => {
  window.open("https://ekadlabs.com");
};

const cursorStyle = { cursor: "pointer" };

const Footer = () => {
  const navigate = useNavigate();
  const handleOnClick = (link) => {
    let path = "";
    switch (link) {
      case routePaths.contact:
        path = routePaths.contact;
        break;
      case routePaths.faq:
        path = routePaths.faq;
        break;
      case routePaths.events:
        path = routePaths.events;
        break;
      case routePaths.orgs:
        path = routePaths.orgs;
        break;
      case routePaths.jobs:
        path = routePaths.jobs;
        break;
      case routePaths.elections:
        path = routePaths.elections;
        break;
      default:
        path = routePaths.home;
    }
    navigate(path);
  };
  return (
    <Stack
      component={"footer"}
      direction={"column"}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      sx={{ bgcolor: "#000000", p: { xs: "15px", md: "40px 80px" } }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        spacing={{ xs: 3, sm: 2, md: 4 }}
      >
        <Box
          component={"img"}
          src={GamlsLogoWhite}
          sx={{
            width:
              window.location.pathname === "/"
                ? { xs: 160, md: 260, lg: 300, sm: 180, xl: 330 }
                : 200,
            height:
              window.location.pathname === "/"
                ? { md: 100, xs: 60, sm: 70, lg: 130, xl: 140 }
                : 60,
          }}
          loading={"lazy"}
        />
        <Stack spacing={{ xs: 2, md: 2 }}>
          <Stack spacing={2}>
            <Typography variant="body1" color={"white"}>
              REACH US ON
            </Typography>

            <Typography
              variant="body2"
              color={"white"}
              gutterBottom
              sx={cursorStyle}
              onClick={() =>
                (window.location.href =
                  "mailto:info@gamls.org?subject=Subject&body=message%20goes%20here")
              }
            >
              {"info@gamls.org"}
            </Typography>

            <Typography
              variant="body2"
              color={"white"}
              gutterBottom
              sx={cursorStyle}
              onClick={() =>
                (window.location.href = "tel:+233 (0) 55 852 1561")
              }
            >
              {"+233 (0) 55 852 1561"}
            </Typography>

            <Typography
              variant="body2"
              color={"white"}
              gutterBottom
              sx={cursorStyle}
              onClick={() =>
                (window.location.href = "tel:+233 (0) 30 268 0011")
              }
            >
              {"+233 (0) 30 268 0011"}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ mt: { md: 2 } }}
          >
            <IconBtn icon={"facebook"} />
            <IconBtn icon={"instagram"} />
            <IconBtn icon={"twitter"} />
            <Box flex={0.7}></Box>
          </Stack>
        </Stack>

        <Stack>
          <Typography
            variant="body1"
            color={"white"}
            gutterBottom
            sx={{ ml: 1 }}
          >
            {"HELPFUL LINKS"}
          </Typography>
          <Grid container>
            {[
              { text: "Events", link: routePaths.events },
              { text: "Jobs & Recuitment", link: routePaths.jobs },
              { text: "Affilate Organizations", link: routePaths.orgs },
              { text: "Contact Us", link: routePaths.contact },
              { text: "FAQ", link: routePaths.faq },
              // { text: "Election HQ", link: routePaths.elections },
            ].map((item) => {
              return (
                <Grid item key={item.text} xs={12} md={6}>
                  <Button
                    variant="text"
                    sx={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => handleOnClick(item.link)}
                  >
                    <Typography
                      color={"white"}
                      textTransform={"initial"}
                      textAlign={"left"}
                    >
                      {item.text}
                    </Typography>
                  </Button>
                </Grid>
              );
            })}
            <Grid item xs={12} md={6}>
              <Button
                variant="text"
                sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
                onClick={() => window.open("https://fgmelsa.com/landing-page")}
              >
                <Typography
                  color={"white"}
                  textTransform={"initial"}
                  textAlign={"left"}
                >
                  FGMELSA
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        sx={{
          borderTop: "2px solid #cecece",
          mt: 5,
          p: "8px 0px",
        }}
      >
        <Typography
          variant="body2"
          color={"white"}
          sx={{ pt: 1, display: { xs: "none", md: "inline-block" } }}
        >
          {"@Copyright 2023 - GAMLS. All Rights Reserved Powered by "}
          <Typography
            component={"span"}
            onClick={openEkad}
            sx={{ cursor: "pointer" }}
          >
            {"EKAD LABS"}
          </Typography>
        </Typography>

        <Stack sx={{ display: { xs: "flex", md: "none" }, mb: 2 }}>
          <Typography variant="body2" color={"white"} sx={{ pt: 1 }}>
            {"@Copyright 2022 - GAMLS."}
          </Typography>
          <Typography variant="body2" color={"white"} sx={{ pt: 1 }}>
            {"All Rights Reserved Powered by "}

            <Typography component={"span"} onClick={openEkad}>
              {"EKAD LABS"}
            </Typography>
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Button
            variant="text"
            sx={{ textTransform: "initial", color: "white" }}
          >
            {"Terms & Conditions"}
          </Button>

          <Button
            variant="text"
            sx={{ textTransform: "initial", color: "white" }}
          >
            {"Privacy Policy"}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
