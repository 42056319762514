import {
  Stepper,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Step,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "white",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "white",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 1,
    border: 0,
    backgroundColor: "white",
    borderRadius: 1,
  },
}));

const CustomStepper = ({ steps }) => {
  return (
    <Stepper
      orientation={"vertical"}
      activeStep={0}
      connector={<StyledConnector />}
    >
      {steps.map((label, i) => {
        return (
          <Step
            key={label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "primary.main",
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "primary.main",
              },
              "& .MuiSvgIcon-root": {
                color: "primary.main",
              },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {},
              "& .MuiStepLabel-root  .MuiStepIcon-text": {},
              "& .MuiStepLabel-label": {
                color: "black",
                fontSize: "16px",
              },
              "& .MuiStepLabel-label.Mui-active": {
                color: "black",
                fontSize: "16px",
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomStepper;
