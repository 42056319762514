import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";
import { auth } from "../data/firebase";
import LoadingBackdrop from "./loadingBackdrop";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const { user: localUser } = useSelector((state) => state.auth);
  const [user, loading] = useAuthState(auth);

  if (!localUser.activated || !user) {
    return loading ? <LoadingBackdrop /> : <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
