import { Box, Typography } from "@mui/material";

const RowText = (props) => {
  const { first = "", second = "", bolden } = props;
  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box flex={1}>
        <Typography
          variant={"body1"}
          gutterBottom
          fontWeight={bolden && "bold"}
        >
          {first}
        </Typography>
      </Box>

      <Box flex={1}>
        <Typography
          variant={"body1"}
          gutterBottom
          fontWeight={bolden && "bold"}
        >
          {second}
        </Typography>
      </Box>
    </Box>
  );
};

export default RowText;
