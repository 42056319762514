export class NewsModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.type = obj.type != null ? obj.type : "";
    this.title = obj.title != null ? obj.title : "";
    this.img_url = obj.img_url != null ? obj.img_url : "";
    this.name = obj.name != null ? obj.name : "";
    this.content = obj.content != null ? obj.content : "";
    this.audience = obj.audience != null ? obj.audience : "";
    this.date = obj.date != null ? obj.date : "";
  }
}

export const newsConverter = {
  toFirestore: (news) => {
    return {
      id: news.id,
      type: news.type,
      title: news.title,
      img_url: news.img_url,
      name: news.name,
      audience: news.audience,
      content: news.content,
      date: news.date,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new NewsModel({
      id: data.id,
      type: data.type,
      title: data.title,
      img_url: data.img_url,
      name: data.name,
      audience: data.audience,
      content: data.content,
      date: data.date.seconds
        ? new Date(data.date.seconds * 1000).toLocaleDateString()
        : data.date,
    });
  },
};
