import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { pressConverter } from "../models/pressModel";
import { saveData } from "./pressSlice";

const readPress = () => {
  let list = [];
  return async (dispatch) => {
    const querySnapshot = await getDocs(
      collection(db, "cms/website/press").withConverter(pressConverter)
    );
    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    dispatch(saveData(list));
  };
};

const updatePressReleases = () => {
  return (dispatch) => {
    const unsubscribe = onSnapshot(
      collection(db, "cms/website/press").withConverter(pressConverter),
      (querySnapshot) => {
        const resources = [];
        querySnapshot.forEach((doc) => {
          resources.push({ ...doc.data(), id: doc.id });
        });
        dispatch(saveData(resources));
      }
    );
  };
};

export { updatePressReleases };
