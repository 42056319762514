import { LocationOn, Timelapse } from "../assets";
import {
  Stack,
  Typography,
  Card,
  CardContent,
  Button,
  Avatar,
  Box,
} from "@mui/material";
import { Markup } from "interweave";
import { NavLink } from "react-router-dom";
import IconedText from "./iconedText";

const JobCard = ({ data }) => {
  const {
    date,
    title,
    desc,
    type,
    region,
    salary,
    deadline,
    img_url,
    company_name,
    id,
    salary_confidential,
  } = data;
  return (
    <Card
      sx={{ bgcolor: "#FFF7F7", borderRadius: "20px", width: { md: "497px" } }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={{ xs: 3, md: 4 }}
          mb={{ xs: 2, md: 4 }}
        >
          <Avatar src={img_url} sx={{ bgcolor: "white" }}/>
          <Typography
            fontWeight={"bold"}
            gutterBottom
            variant="body2"
            textTransform={"capitalize"}
          >
            {company_name}
          </Typography>
        </Stack>

        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Box sx={{ maxHeight: 100, overflow: "hidden" }}>
          <Markup content={desc} />
        </Box>
        <Stack
          direction={"row"}
          flex={1}
          justifyContent={"space-between"}
          py={2}
        >
          <IconedText
            text={region}
            icon={<LocationOn fontSize="12px" color="text.secondary" />}
            secondary
          />
          <IconedText
            text={type}
            icon={<Timelapse fontSize="12px" color="text.secondary" />}
          />
        </Stack>
        <Typography gutterBottom color={"primary"} fontWeight={"bold"}>
          {"Posted: "}
          <span style={{ color: "black", fontWeight: "normal" }}>{date}</span>
        </Typography>
        <Typography
          gutterBottom
          color={"primary"}
          fontWeight={"bold"}
          sx={{ mb: 4 }}
        >
          {"Deadline: "}
          <span style={{ color: "black", fontWeight: "normal" }}>
            {deadline}
          </span>
        </Typography>
        <Stack
          flexDirection={"row"}
          flex={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography gutterBottom color={"primary"} fontWeight={"bold"}>
            {"Salary: "}
            <Typography
              color={"text.primary"}
              component={"span"}
              fontWeight={"bold"}
            >
              {salary_confidential ? "Confidential" : salary}
            </Typography>
          </Typography>

          <Button
            variant={"contained"}
            sx={{ backgroundColor: "black", borderRadius: 3, px: 6 }}
            LinkComponent={NavLink}
            to={`/jobs/${id}`}
          >
            {"Apply"}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default JobCard;
