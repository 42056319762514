import { ArrowBackIos, ArrowForwardIos } from "../../assets";
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { Title, CustomSelector } from "../../components";

const tabs = ["Regulatory", "Policy", "Member Tools"];

export const Content = () => {
  const [page, setPage] = React.useState(1);
  const [hasUser] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const { data: resources } = useSelector((state) => state.resource);
  const filterResourceByType = useCallback(
    (v) => {
      return resources.filter((x) => x.type === tabs[v]?.toLowerCase());
    },
    [resources]
  );
  const [resourcesByType, setResourcesByType] = React.useState(
    filterResourceByType(value)
  );

  const handleTabChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      setResourcesByType(filterResourceByType(newValue));
    },
    [filterResourceByType]
  );

  const handleChange = (v) => {
    setPage(v);
  };

  const onClick = useCallback((resourceUrl) => {
    window.open(resourceUrl);
  }, []);

  return (
    <Box
      sx={{
        px: { xs: "15px", md: "140px" },
        py: { md: 12, xs: 6 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: { md: 32 },
        }}
      >
        <Title
          title={"Polices, Member Resources & Tools"}
          subTitle={
            "The Ghana Association of Medical Lab Scientist represents leading medical laboratories that deliver essential diagnostic information to patients and health care providers. GAMLS’s membership provides members with access to innovative tools, trainings and industry-wide collaboration."
          }
          underline
          alignMe
          alignHead
        />
      </Box>

      <Box sx={{ py: 6 }}>
        <Box
          flex={1}
          sx={{ display: { xs: "none", md: hasUser ? "flex" : "none" }, pb: 3 }}
        >
          <CustomSelector values={["1"]} label={"Resorce type"} />
          <CustomSelector values={["1"]} label={"Resource Category"} />
        </Box>

        <Stack
          sx={{
            borderColor: "divider",
            borderBottom: 1,
            alignItems: "center",
            mb: { xs: 6, md: 12 },
            mx: { xs: 0, md: 12 },
            display: hasUser ? "none" : "flex",
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#F30813" } }}
          >
            {tabs.map((name) => (
              <Tab label={name} key={name} />
            ))}
          </Tabs>
        </Stack>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {resourcesByType.map((resource, id) => {
            return (
              <Grid item key={id} xs={2} sm={4} md={4}>
                <Title
                  title={resource.title}
                  subTitle={resource.name}
                  inverse
                  isClickable={true}
                  onClick={() => onClick(resource.img_url)}
                />
              </Grid>
            );
          })}
        </Grid>

        {/* <Stack spacing={2} alignItems={"center"} sx={{ pt: 6 }}>
          <Pagination
            count={10}
            onChange={handleChange}
            renderItem={(item) => (
              <PaginationItem
                page={page}
                components={{ previous: ArrowBackIos, next: ArrowForwardIos }}
                {...item}
              />
            )}
          />
        </Stack> */}
      </Box>
    </Box>
  );
};
