import { LocationOn, Timelapse } from "../../assets";
import { Markup } from "interweave";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { JobModel } from "../../data";

const {
  Box,
  CssBaseline,
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
} = require("@mui/material");
const {
  Banner,
  Title,
  IconedText,
  IconBtn,
  Newsletter,
} = require("../../components");

function createData(key, value) {
  return { key, value };
}

const Content = ({ job }) => {
  const rows = useMemo(() => {
    return [
      createData("Medium Qualification:", job.qualification),
      createData("Experience Level:", job.exp_level),
      createData("Experience length:", `${job.exp_length} years`),
    ];
  }, [job.qualification, job.exp_level, job.exp_length]);

  return (
    <Box sx={{ py: { xs: 3, md: 6 }, px: { xs: "15px", md: "140px" }, pb: 16 }}>
      <Stack
        my={3}
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        spacing={{ xs: 2, md: 3 }}
      >
        <Box>
          <Avatar src={job.img_url} />
        </Box>
        <Typography variant={"body1"} fontWeight={"bold"}>
          {job.company_name}
        </Typography>
      </Stack>

      <Stack direction={"row"} spacing={12}>
        <Box flexGrow={1}>
          <Card elevation={0} sx={{ bgcolor: "#FAFAFA", borderRadius: 5 }}>
            <CardContent>
              <Box>
                <Title title={job.title} />

                <Stack direction={"row"} alignItems={"center"} spacing={6}>
                  <IconedText
                    text={job.region}
                    icon={<LocationOn fontSize="12px" color="text.secondary" />}
                    secondary
                  />
                  <IconedText
                    text={job.type}
                    icon={<Timelapse fontSize="12px" color="text.secondary" />}
                  />
                  <Typography
                    gutterBottom
                    color={"primary"}
                    fontWeight={"bold"}
                    sx={{ display: { xs: "none", md: "inline-block" } }}
                  >
                    {"Salary: "}
                    <span style={{ color: "black", fontWeight: "normal" }}>
                      {job.salary_confidential ? "Confidential" : job.salary}
                    </span>
                  </Typography>
                </Stack>

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent={"space-between"}
                  py={3}
                >
                  <Typography
                    gutterBottom
                    color={"primary"}
                    fontWeight={"bold"}
                    sx={{ display: { xs: "inline-block", md: "none" } }}
                  >
                    {"Salary: "}
                    <span style={{ color: "black", fontWeight: "normal" }}>
                      {job.salary_confidential ? "Confidential" : job.salary}
                    </span>
                  </Typography>
                  <Typography
                    gutterBottom
                    color={"primary"}
                    fontWeight={"bold"}
                  >
                    {"Posted: "}
                    <span style={{ color: "black", fontWeight: "normal" }}>
                      {job.date}
                    </span>
                  </Typography>

                  <Typography
                    gutterBottom
                    color={"primary"}
                    fontWeight={"bold"}
                  >
                    {"Deadline: "}
                    <span style={{ color: "black", fontWeight: "normal" }}>
                      {job.deadline}
                    </span>
                  </Typography>
                </Stack>
              </Box>

              <Divider />

              <Box mt={6}>
                <Title title={"Job Summary"} />
                <Markup content={job.summary} />

                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.key}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ fontWeight: "bold" }}
                          >
                            {row.key}
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {row.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box mt={6}>
                <Title title={"Job Description"} />
                <Markup content={job.desc} />
              </Box>
            </CardContent>
          </Card>

          <Box>
            <Typography gutterBottom mt={3}>
              {"Share Job"}
            </Typography>

            <Stack direction={"row"}>
              <IconBtn icon={"facebook"} id={job.id} route={"jobs"} />
              <IconBtn icon={"twitter"} id={job.id} route={"jobs"} />
            </Stack>
          </Box>
        </Box>

        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
          <Title title={"Similar Jobs"} underline xSpace />
          {/* <JobCard
            location={"Kasoa Polyclinic"}
            title={"Job Tittle"}
            desc={placeHolderText.medium}
            region={"Greater Accra"}
            category={"Full Time"}
            date={"Fri, Jun 23, 2022"}
            salary={"¢2500"}
          /> */}
        </Box>
      </Stack>

      <Box my={{ xs: 3, md: 6 }}>
        <Newsletter />
      </Box>
    </Box>
  );
};

const JobDetails = () => {
  const { id } = useParams();
  const { data: events } = useSelector((state) => state.job);
  const job = new JobModel({ ...events.find((x) => x.id === id) });

  const bannerProps = {
    title: "Jobs",
    subTitle: job.title,
  };

  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content job={job} />
    </>
  );
};

export default JobDetails;
