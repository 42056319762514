import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";

const postUserLearning = async (userId, lesson) => {
  try {
    const learningRef = collection(
      db,
      "user-learnings",
      userId,
      lesson.categoryId
    );
    await addDoc(learningRef, {
      values: lesson.values,
      date: serverTimestamp(),
    });
  } catch (error) {
    console.log(error);
  }
};

const getUserLearning = (uid) => {
  return async (dispatch) => {
    try {
      const q = query(doc(db, "user-learnings", uid));
      onSnapshot(q, (doc) => {
        console.log("Current data: ", doc.data());
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export { postUserLearning, getUserLearning };
