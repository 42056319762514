import { SvgIcon } from "@mui/material"
import * as React from "react"

function EmailOutlined(props) {
  return (
    <SvgIcon
      {...props}
    >
      <path
        d="M4 9l6.2 4.65a3 3 0 003.6 0L20 9"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.177a2 2 0 011.029-1.749l7-3.888a2 2 0 011.942 0l7 3.888A2 2 0 0121 9.177V17a2 2 0 01-2 2H5a2 2 0 01-2-2V9.177z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </SvgIcon>
  )
}

export default EmailOutlined
