import { CssBaseline } from "@mui/material";
import { Banner } from "../../components";
import { Content } from "./newsElements";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { updateNews, updatePressReleases } from "../../data";

const News = () => {
  const dispatch = useDispatch();
  const bannerProps = {
    title: "News & PR",
    subTitle: "News and Related Public Relations Informations from GAMLS",
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(updateNews());
      dispatch(updatePressReleases());
    }, 2000);

    return () => clearTimeout(getData);
  }, [dispatch]);
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default News;
