import { Stack, Typography } from "@mui/material";
import Underline from "./underline";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";

const Title = (props) => {
  const {
    title = "",
    titleSecondary = "",
    subTitle = "",
    subTitleSecondary = "",
    underline,
    xSpace,
    alignMe,
    inverse,
    noWrap,
    isClickable = false,
    onClick = () => {},
    alignHead,
  } = props;

  const matches = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  return (
    <Stack sx={{ cursor: isClickable && "pointer" }} onClick={onClick}>
      <Typography
        variant={inverse ? "caption" : "h5"}
        gutterBottom
        fontWeight={inverse ? "inherit" : "bold"}
        color={(titleSecondary || inverse) && "primary"}
        whiteSpace={{
          sm: noWrap && "inherit",
          md: noWrap && "wrap",
          xs: noWrap && "nowrap",
          lg: noWrap && "nowrap",
        }}
        sx={{ textAlign: alignHead && "center" }}
      >
        {title || titleSecondary}
      </Typography>
      {underline && <Underline alignHead={alignHead} />}
      <Typography
        variant={"body1"}
        color={subTitleSecondary && "primary"}
        gutterBottom
        sx={{ mb: xSpace && 2, textAlign: alignMe && "center" }}
      >
        {subTitle || subTitleSecondary}
      </Typography>
    </Stack>
  );
};

export default Title;
