import { useEffect } from "react";
import { Content } from "./loginElements";
import { routePaths } from "../../constants";
import { useLogin } from "./useLogin";
import { LoadingBackdrop } from "../../components";

const Login = () => {
  const { user, navigate, getUser, dispatch, loading } = useLogin();
  useEffect(() => {
    if (user) {
      getUser(dispatch);
      navigate(routePaths.home);
    }
  }, [navigate, user, getUser, dispatch]);
  return loading ? <LoadingBackdrop /> : <Content />;
};
export default Login;
