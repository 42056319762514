import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import Image from "mui-image";
import React, { useCallback, useEffect, useState } from "react";
import { IconBtn, Newsletter } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import Map from "../../components/map";
import { Markup } from "interweave";
import { useSelector } from "react-redux";

export const DetailedEvent = () => {
  const navigate = useNavigate();
  const [event, setEvent] = useState({});
  const { data } = useSelector((state) => state.events);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const e = data.find((x) => x.id === id);
      setEvent(e);
    }
  }, [data, id]);

  const handleNavigate = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box
      sx={{
        px: { xs: 0, md: "140px" },
        mb: 6,
      }}
    >
      <Stack>
        <Box sx={{ height: { xs: "200px", md: "400px" } }}>
          <Image src={event.img_url} width={"100%"} />
        </Box>

        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          sx={{ borderBottom: "1px solid #cecece" }}
          py={3}
          px={{ xs: 2 }}
        >
          <Stack
            spacing={{ xs: 1, md: 2 }}
            direction={{ xs: "column", md: "row" }}
            divider={<Divider orientation="vertical" flexItem />}
            mb={{ xs: 2 }}
          >
            <Typography
              variant={"h5"}
              fontWeight={"bold"}
              onClick={handleNavigate}
              sx={{ cursor: "pointer" }}
            >
              {"Events"}
            </Typography>

            <Typography
              variant={"h5"}
              fontWeight={"bold"}
              textTransform={"uppercase"}
            >
              {event.title}
            </Typography>
          </Stack>

          <Button
            variant={"contained"}
            disableElevation
            sx={{
              display: "none",
              borderRadius: "16px",
              px: { xs: 3, md: 8 },
              width: { xs: "fit-content" },
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            {"Register"}
          </Button>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          flexGrow={1}
          spacing={{ xs: 6, md: 12 }}
          py={{ xs: 3, md: 6 }}
          px={{ xs: 2 }}
        >
          <Stack
            sx={{ maxWidth: { xs: "100%", md: "50%" } }}
            spacing={3}
            flexGrow={1}
          >
            <Stack>
              <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
                {"About This Event"}
              </Typography>
              <Markup content={event.desc} />
            </Stack>

            <Stack spacing={2} sx={{ display: { xs: "none", md: "inherit" } }}>
              <Typography variant={"body2"}>{"Share Event"}</Typography>
              <Stack direction={"row"}>
                {["facebook", "twitter"].map((type) => {
                  return (
                    <IconBtn
                      key={type}
                      sx={{ p: 0 }}
                      icon={type}
                      id={event.id}
                      route={"events"}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={6} flexGrow={1}>
            <Stack spacing={2}>
              <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
                {"Event Period"}
              </Typography>
              <Typography
                variant={"body2"}
                fontWeight={"bold"}
                color={"primary.main"}
              >
                {"Sarts: "}
                <Typography component={"span"} color={"text.primary"}>
                  {new Date(event?.from).toDateString()}
                </Typography>
              </Typography>
              <Typography
                variant={"body2"}
                fontWeight={"bold"}
                color={"primary.main"}
              >
                {"Ends: "}
                <Typography component={"span"} color={"text.primary"}>
                  {new Date(event?.to).toDateString()}
                </Typography>
              </Typography>
            </Stack>
            <Stack>
              <Typography variant={"h5"} fontWeight={"bold"}>
                {"Event Location"}
              </Typography>

              <Map url={event.location_url} />

              <Typography component={"span"} color={"text.primary"}>
                {event?.location}
              </Typography>
            </Stack>

            <Stack spacing={2} sx={{ display: { xs: "inherit", md: "none" } }}>
              <Typography variant={"body2"}>{"Share Event"}</Typography>
              <Stack direction={"row"}>
                {["facebook", "twitter"].map((type) => {
                  return (
                    <IconBtn
                      key={type}
                      sx={{ p: 0 }}
                      icon={type}
                      id={event.id}
                      route={"events"}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Box px={{ xs: "15px", md: 0 }}>
          <Newsletter />
        </Box>
      </Stack>
    </Box>
  );
};
