const reducers = {
  post: "post",
  read: "read",
  resource: "resources",
  job: "jobs",
  banner: "banner",
  news: "news",
  event: "events",
  press: "press",
  user_auth: "auth",
  notifications: "notifications",
  elections: "elections",
  lessons: "lessons",
  categories: "categories",
  courses: "courses",
  auth: "auth",
};

const actionIcons = {
  VIEW: "view",
  ADD_USER: "add-user",
  EDIT: "edit",
  DELETE: "delete",
  FILTER: "filter",
};

const regions = [
  "Ahafo Region",
  "Ashanti Region",
  "Bono-East Region",
  "Brong Ahafo Region",
  "Central Region",
  "Eastern Region",
  "Greater Accra Region",
  "North-East Region",
  "Northern Region",
  "Oti Region",
  "Savannah Region",
  "Upper-East Region",
  "Upper-West Region",
  "Western Region",
  "Western-North Region",
  "Volta Region",
];

const jobQualification = [
  "Basic",
  "Junior",
  "Secondary",
  "Tertiary",
  "Masters",
  "Doctorate",
];

const experienceLevel = [
  "Beginner",
  "Novice",
  "Intermediate",
  "Advanced",
  "Specialist",
];

const experienceLength = ["Less than 1 year", "1-3", "3-5", "5-10", "10+"];

const jobType = ["Part time", "Full time", "Contract"];

export {
  reducers,
  actionIcons,
  regions,
  experienceLevel,
  jobQualification,
  experienceLength,
  jobType,
};
