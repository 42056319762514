const Underline = ({ alignHead }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "16%",
        marginBottom: "8px",
        alignSelf: alignHead && "center",
      }}
    >
      <div
        style={{
          border: "1px solid #F30813",
          marginRight: "5px",
          width: "60%",
          height: "1px",
        }}
      ></div>
      <div
        style={{
          border: "1px solid #F30813",
          marginRight: "5px",
          width: "30%",
          height: "1px",
        }}
      ></div>
      <div
        style={{
          border: "1px solid #F30813",
          width: "20%",
          height: "1px",
        }}
      ></div>
    </div>
  );
};

export default Underline;
