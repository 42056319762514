import { Banner, JobCard, Newsletter, Title } from "../../components/index";
import { Box, Stack, Typography, CssBaseline, Tabs, Tab } from "@mui/material";
import React from "react";
import { placeHolderText } from "../../constants";
import { useSelector } from "react-redux";

const Content = () => {
  const [value, setValue] = React.useState(0);
  const { data: jobs } = useSelector((state) => state.job);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack
      spacing={6}
      flexGrow={1}
      sx={{ width: "100%", py: 6, px: { xs: "15px", md: "80px" } }}
    >
      <Stack direction={"row"} flexGrow={1} spacing={{ xs: 0, md: 6 }}>
        <Box flex={{ xs: 1, md: jobs.length === 0 ? 1 : 0.8 }}>
          <Stack
            sx={{
              borderTop: "1px solid #707070",
              borderBottom: "1px solid #707070",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: "#F30813" } }}
            >
              <Tab label={"All"} />
              <Tab label={"Policy"} />
              <Tab label={"Regulatory"} />
            </Tabs>
          </Stack>
          <Stack sx={{ my: 4 }} spacing={4}>
            <Stack>
              <Typography color={"primary"} gutterBottom>
                {"Lorem Ipsum Dolor aur amet?"}
              </Typography>
              <Typography gutterBottom>{placeHolderText.medium}</Typography>
            </Stack>
            <Typography color={"primary"} gutterBottom>
              {"Lorem Ipsum Dolor aur amet?"}
            </Typography>
            <Typography color={"primary"} gutterBottom>
              {"Lorem Ipsum Dolor aur amet?"}
            </Typography>
            <Typography color={"primary"} gutterBottom>
              {"Lorem Ipsum Dolor aur amet?"}
            </Typography>
          </Stack>
        </Box>
        {jobs.length !== 0 && (
          <Box
            sx={{
              display: { xs: "none", md: "inherit" },
              flexDirection: "column",
            }}
            flex={0.2}
          >
            <Title title={"Jobs"} underline />
            <JobCard
              data={{
                location: "Kasoa Polyclinic",
                title: "Job Tittle",
                desc: placeHolderText.medium,
                region: "Greater Accra",
                category: "Full Time",
                date: "Fri, Jun 23, 2022",
                salary: "¢2500",
              }}
            />
          </Box>
        )}
      </Stack>
      <Newsletter />
    </Stack>
  );
};

const Faq = () => {
  const bannerProps = {
    title: "Frequently Asked Questions",
    subTitle: "Find the right answers to most of your asked questions!",
  };
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default Faq;
