import { SvgIcon } from "@mui/material";
import { ReactComponent as ShareOutlinedIcon } from '../icons/ic_share.svg';

function ShareOutlined(props){
    return  <SvgIcon
    {...props}
    component={ShareOutlinedIcon}
    inheritViewBox
  />
}

export default ShareOutlined