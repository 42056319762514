import { Box, Stack } from "@mui/material";
import Image from "mui-image";
import { Background } from "../../assets";
import { Newsletter, Title } from "../../components";
import { DividedText, PressCard } from "./newsElements";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NewsModel, PressModel } from "../../data";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useEffect, useState } from "react";

export const NewsDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.news);
  const { data: press } = useSelector((state) => state.press);
  const [news, setNews] = useState([]);

  useEffect(() => {
    if (state && state.is_press) {
      setNews(new PressModel({ ...press.find((x) => x.id === id) }));
    } else {
      setNews(new NewsModel({ ...data.find((x) => x.id === id) }));
    }
  }, [data, id, setNews, state, press]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Box sx={{ py: { xs: 6, md: 6 }, px: { xs: "15px", md: "80px" } }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={6}>
          <Box flex={1.5}>
            <DividedText
              first={"News & PR"}
              second={news?.type}
              secondary
              colored
              onFirstClicked={() => navigate(-1)}
            />
            <Title title={news?.title} />
            <DividedText first={news?.date} second={`BY: ${news?.name}`} />

            <Stack spacing={3} sx={{ fontSize: 16 }}>
              {state && state.is_press ? null : <Image src={news?.img_url} />}
              <Interweave content={news?.content} />
            </Stack>
          </Box>

          <Box
            flex={0.5}
            sx={{
              display: {
                xs: "none",
                md: state && state.is_press ? "inherit" : "none",
              },
            }}
          >
            <PressCard
              height={"100%"}
              data={
                state && state.is_press
                  ? press.filter((x) => x.id !== id)
                  : press
              }
            />
          </Box>
        </Stack>

        <Stack pt={{ xs: 3, md: 6 }}>
          {/* <BlogList noPadding /> */}

          <Newsletter />
        </Stack>
      </Box>
    </Box>
  );
};

export default NewsDetails;
