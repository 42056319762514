import { Banner, ExecutiveSlider, Title } from "../../components/index";
import {
  Box,
  Stack,
  CssBaseline,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  tableCellClasses,
} from "@mui/material";
import React from "react";
import { executives, placeHolderText } from "../../constants";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 24,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const ActivitiesContent = () => {
  function createData(name, date, location) {
    return { name, date, location };
  }

  const rows = [
    createData(
      "The quick, brown fox jumps",
      "Fri, Jun 23, 2022",
      "Greater Accra, University of Ghana"
    ),
    createData(
      "The quick, brown fox jumps",
      "Sat, Aug 23, 2022",
      "Greater Accra, University of Education"
    ),
    createData(
      "The quick, brown fox jumps",
      "Sat, Oct 12, 2022",
      "Greater Accra, University of Cape Coast"
    ),
    createData(
      "The quick, brown fox jumps",
      "Sat, Nov 2, 2022",
      "Greater Accra, Accra Technical University"
    ),
    createData(
      "The quick, brown fox jumps",
      "Sat, Dec 01, 2022",
      "Greater Accra, University of Health And Allied Science"
    ),
  ];

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "20px",
        bgcolor: "#EAEAEA",
        p: { xs: 2, md: 5 },
        mb: 12,
      }}
    >
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{"Activity"}</StyledTableCell>
            <StyledTableCell>{"Date"}</StyledTableCell>
            <StyledTableCell>{"Location"}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell>{row.location}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Content = () => {
  return (
    <Box
      flexGrow={1}
      sx={{
        minWidth: "100%",
        py: 6,
        px: { xs: "15px", md: "140px" },
        pb: { xs: 3, md: 16 },
      }}
    >
      <Title
        title={"About Medical Laboratory Students Association"}
        subTitle={placeHolderText.medium}
        xSpace
        underline
      />
      <Stack sx={{ py: { xs: 3, md: 8 } }} spacing={{ xs: 3, md: 6 }}>
        <Stack sx={{ width: { xs: "100%", md: "30%" } }}>
          <Title title={"Our Executives"} underline />
        </Stack>
        <ExecutiveSlider executives={executives} />

        <Stack sx={{ width: { xs: "100%", md: "30%" } }}>
          <Title title={"Activities"} underline />
        </Stack>
        <ActivitiesContent />
      </Stack>
    </Box>
  );
};
const Organizations = () => {
  const bannerProps = {
    title: "Welcome to MLSA",
    subTitle: placeHolderText.short,
    color: "#000000",
  };
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default Organizations;
