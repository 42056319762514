import {
  Stack,
  Tab,
  Box,
  Divider,
  Typography,
  Grid,
  TextField,
  Button,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LetterAvatar, Title } from "../../components";
import { registrationSteps } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getUser, sendPasswordReset } from "../../data/firebase";
import { useNavigate, useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { SelectInput } from "../learning/learningElements";
import { useFormik } from "formik";
import { userValidationSchema } from "../../utils/validationSchema";
import { getUserLearning } from "../../data/auth/authAction";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../data/firebase";

export const fieldStyle = {
  "& input": {
    paddingLeft: "20px",
    bgcolor: "white",
    borderRadius: "20px",
  },
  "& fieldset": {
    borderRadius: "20px",
  },
  "& .MuiInputBase-input": {
    bgcolor: "white",
    borderRadius: "20px",
  },
};

const options = [
  { name: "Most recent", value: "recent" },
  { name: "Popular", value: "popular" },
  { name: "Recommended", value: "rec" },
];

export const PrimaryButton = ({ text = "Submit", onClick = () => {} }) => (
  <Button
    variant={"contained"}
    disableElevation
    sx={{
      width: { xs: "100%", md: "32%" },
      height: "48px",
      bgcolor: "black",
      textTransform: "capitalize",
      fontSize: 16,
      borderRadius: 5,
      my: 6,
      fontWeight: "bold",
      whiteSpace: "nowrap",
    }}
    onClick={onClick}
  >
    {text}
  </Button>
);

const ProfilePanel = ({ user, navigate }) => {
  const [resetEmail, setResetEmail] = useState("");
  const fullName =
    user.firstName && user.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : "Unidentified User";

  const formik = useFormik({
    initialValues: user,
    validationSchema: userValidationSchema,
    enableReinitialize: true,
  });

  return (
    <Box>
      <Stack
        sx={{ overflow: "hidden", px: { xs: 0, md: 8 } }}
        spacing={{ xs: 2, md: 4 }}
      >
        <Stack direction={"row"} spacing={{ xs: 2, md: 4 }}>
          <LetterAvatar name={fullName} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack spacing={{ xs: 1, md: 2 }} justifyContent={"center"}>
            <Typography variant={"h5"} fontWeight={"bold"}>
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              alignItems={{ xs: "flex-start", md: "center" }}
            >
              {user.memberId ? <Typography>{user?.memberId}</Typography> : null}
              <Chip
                label={user.activated ? "Activated" : "Pending Activation"}
                color={user.activated ? "success" : "primary"}
              />
            </Stack>
            <Typography
              sx={{
                fontSize: { xs: 8, md: "inherit" },
              }}
            >
              {user?.email}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
            Update Your Profile
          </Typography>
          <Typography gutterBottom color={"text.secondary"}>
            Add information about yourself
          </Typography>
        </Stack>
      </Stack>

      <Box px={{ xs: 0, md: 12 }} py={2}>
        {registrationSteps.map((item, index) => {
          return (
            registrationSteps.length - 1 === index || (
              <Box key={index} component="form" noValidate autoComplete="off">
                <Typography variant={"body1"} fontWeight={"bold"} pb={2} pt={6}>
                  {item.title}
                </Typography>
                <Grid
                  container
                  rowSpacing={{ xs: 3, md: 4 }}
                  columnSpacing={{ md: 3, xs: 1 }}
                  columns={{ xs: 2, sm: 8, md: 12 }}
                >
                  {item.data.map((field, i) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} key={i}>
                        {field.menu ? (
                          <TextField
                            sx={fieldStyle}
                            key={i}
                            id={`${field.placeholder}-field`}
                            select
                            fullWidth
                            defaultValue={user[field?.id]}
                            label={field.placeholder}
                            SelectProps={{
                              native: true,
                            }}
                            variant={"outlined"}
                          >
                            {field.menu.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </TextField>
                        ) : (
                          <TextField
                            fullWidth
                            sx={fieldStyle}
                            key={i}
                            defaultValue={user[field?.id]}
                            variant={"outlined"}
                            id={`${field.placeholder}-field`}
                            label={field.placeholder}
                            type={field.type}
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )
          );
        })}
        <PrimaryButton text={"Save Data"} />
        <Stack spacing={3}>
          <Box>
            <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
              {"Account Security"}
            </Typography>
            <Typography gutterBottom color={"text.secondary"}>
              {
                "To change your password, a reset link will be sent to your email"
              }
            </Typography>
          </Box>

          <TextField
            sx={{ ...fieldStyle, maxWidth: { xs: "100%", md: "32%" } }}
            variant={"outlined"}
            label={"jdoe@examplemail.com"}
            placeholder={"jdoe@examplemail.com"}
            type={"email"}
            value={resetEmail}
            onChange={(e) => setResetEmail(e?.target?.value)}
          />
          <PrimaryButton
            text={"Change"}
            onClick={() => sendPasswordReset(resetEmail, navigate)}
          />
        </Stack>
      </Box>
    </Box>
  );
};

const NotificationsPanel = () => {
  return (
    <Stack spacing={2} alignItems={"center"} py={{ xs: 6, md: 12 }}>
      <Typography variant={"h4"} color={"text.secondary"} fontWeight={"bold"}>
        {"No Notifications"}
      </Typography>
    </Stack>
  );
};

const LearningPanel = ({ lessons, isFetching }) => {
  return (
    <Stack spacing={2} px={{ xs: 0, md: "80px" }}>
      <Title title={"Courses You're Taking"} />

      <Stack direction={"row"} spacing={2}>
        <SelectInput label={"Order By"} options={options} />
        <Button>{"Clear"}</Button>
      </Stack>

      {/* <Grid
        container
        rowSpacing={{ xs: 2, md: 6 }}
        columnSpacing={{ xs: 2, md: 2 }}
      >
        {lessons?.map((lesson, index) => {
          return (
            <Grid item container key={index} xs={12} md={6}>
              <LearningCard
                lessonCount={lesson.lessonCount}
                status={lesson.status}
                title={lesson.title}
              />
            </Grid>
          );
        })}
      </Grid> */}
    </Stack>
  );
};

export const Content = () => {
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();
  const {
    userLearnings,
    user: localUser,
    learningsLoading,
  } = useSelector((state) => state.auth);
  const [user] = useAuthState(auth);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (id) {
      case "learning":
        setValue("2");
        break;
      case "notifications":
        setValue("3");
        break;
      default:
        setValue("1");
        break;
    }
  }, [id]);
  useEffect(() => {
    dispatch(getUserLearning(user.uid));
  }, [dispatch, user.uid]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack flexGrow={1} spacing={2} py={6}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            px: { xs: "15px", md: "80px" },
          }}
        >
          <TabList
            variant="scrollable"
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: "#F30813" } }}
            scrollButtons={"auto"}
            allowScrollButtonsMobile
            aria-label="lab API tabs example"
          >
            {["My Personal Profile", "My Learning", "Notifications"].map(
              (title, idx) => (
                <Tab label={title} key={title} value={`${idx + 1}`} />
              )
            )}
          </TabList>
        </Box>
        <TabPanel value={"1"}>
          <ProfilePanel user={localUser} navigate={navigate} />
        </TabPanel>
        <TabPanel value={"2"}>
          <LearningPanel
            lessons={userLearnings}
            isFetching={learningsLoading}
          />
        </TabPanel>
        <TabPanel value={"3"}>
          <NotificationsPanel />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};
