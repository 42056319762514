import { CssBaseline } from "@mui/material";
import React from "react";
import { Banner } from "../../../components";
import { FormationContent } from "../aboutElements";

const bannerProps = {
  title: "About Us",
  otherTitle: "Our Formation",
  subTitle:
    "Brief Profile Of The Ghana Association Of Medical Laboratory Scientistsa Region",
};

const Formation = () => {
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <FormationContent />
    </>
  );
};
export default Formation;
