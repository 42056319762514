export class JobModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.title = obj.title != null ? obj.title : "";
    this.company_name = obj.company_name != null ? obj.company_name : "";
    this.img_url = obj.img_url != null ? obj.img_url : "";
    this.region = obj.region != null ? obj.region : "";
    this.type = obj.type != null ? obj.type : "";
    this.deadline = obj.deadline != null ? obj.deadline : "";
    this.salary = obj.salary != null ? obj.salary : "";
    this.salary_confidential =
      obj.salary_confidential != null ? obj.salary_confidential : false;
    this.summary = obj.summary != null ? obj.summary : "";
    this.qualification = obj.qualification != null ? obj.qualification : "";
    this.exp_level = obj.exp_level != null ? obj.exp_level : "";
    this.exp_length = obj.exp_length != null ? obj.exp_length : "";
    this.desc = obj.desc != null ? obj.desc : "";
    this.date = obj.date != null ? obj.date : "";
  }
}

export const jobConverter = {
  toFirestore: (job) => {
    return {
      id: job.id,
      title: job.title,
      company_name: job.company_name,
      img_url: job.img_url,
      region: job.region,
      type: job.type,
      deadline: job.deadline,
      salary: job.salary,
      salary_confidential: job.salary_confidential,
      summary: job.summary,
      qualification: job.qualification,
      exp_level: job.exp_level,
      exp_length: job.exp_length,
      desc: job.desc,
      date: job.date,
    };
  },
  fromFirestore: (snapshot, options) => {
    const job = snapshot.data(options);
    return new JobModel({
      id: job.id,
      title: job.title,
      company_name: job.company_name,
      img_url: job.img_url,
      region: job.region,
      type: job.type,
      deadline: job.deadline,
      salary: job.salary,
      salary_confidential: job.salary_confidential,
      summary: job.summary,
      qualification: job.qualification,
      exp_level: job.exp_level,
      exp_length: job.exp_length,
      desc: job.desc,
      date: job.date.seconds
        ? new Date(job?.date?.seconds * 1000).toLocaleDateString()
        : job.date,
    });
  },
};
