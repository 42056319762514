class ResourceModel {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id != null ? obj.id : "";
    this.type = obj.type != null ? obj.type : "";
    this.title = obj.title != null ? obj.title : "";
    this.img_url = obj.img_url != null ? obj.img_url : "";
    this.name = obj.name != null ? obj.name : "";
    this.date = obj.date != null ? obj.date : "";
  }
}
const resourceConverter = {
  toFirestore: (resource) => {
    return {
      id: resource.id,
      type: resource.type,
      title: resource.title,
      img_url: resource.img_url,
      name: resource.name,
      date: resource.date,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new ResourceModel({
      id: data.id,
      type: data.type,
      title: data.title,
      img_url: data.img_url,
      name: data.name,
      date: data.date.seconds
        ? new Date(data.date.seconds * 1000).toLocaleDateString()
        : data.date,
    });
  },
};

export { ResourceModel, resourceConverter };
