import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { eventConverter } from "../models/eventModel";
import { init, saveData } from "./eventSlice";

const updateEvents = () => {
  return (dispatch) => {
    dispatch(init());
    onSnapshot(
      collection(db, "cms/website/events").withConverter(eventConverter),
      (querySnapshot) => {
        const resources = [];
        querySnapshot.forEach((doc) => {
          resources.push({ ...doc.data(), id: doc.id });
        });
        dispatch(saveData(resources));
      }
    );
  };
};

export { updateEvents };
