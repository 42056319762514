import { Banner } from "../../components/index";
import { CssBaseline } from "@mui/material";
import React from "react";
import { Content } from "./branchesElements";
import UnderConstruction from "../../components/underConstruction";

const Branches = () => {
  const bannerProps = {
    title: "Branches",
    subTitle: "Greater Accra Region",
  };
  return (
    <>
      <CssBaseline />
      <Banner {...bannerProps} />
      <Content />
    </>
  );
};
export default Branches;
