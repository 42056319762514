import { EmailOutlined, CallOutlinedIcon,Meeting } from "../../assets";
import { Box, Paper, Tabs, Tab, Typography, Stack } from "@mui/material";
import React from "react";
import {
  executives,
  meetingDays,
  placeHolderText,
  regions,
  steps,
} from "../../constants";
import {
  CustomStepper,
  ExecutiveSlider,
  IconedText,
  RowText,
  Title,
} from "../../components";

const BranchSlider = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "100%",
        py: { xs: 3, md: 8 },
      }}
    >
      <Paper
        sx={{
          display: "flex",
          borderRadius: "20px",
          py: "36px",
          px: { xs: "15px", md: "60px" },
          maxWidth: "100%",
          bgcolor: "white",
        }}
      >
        <Box
          flex={1}
          sx={{
            mx: { xs: 0, md: 2 },
            maxWidth: "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant={"scrollable"}
            scrollButtons={"auto"}
            indicatorColor={"primary"}
            allowScrollButtonsMobile
            textColor={"primary"}
            sx={{
              color: "#F30813",
              ".MuiButtonBase-root.MuiTab-root.Mui-selected ": {
                fontWeight: "bold",
                color: "primary.main",
              },
              ".MuiButtonBase-root.MuiTab-root": {
                color: "black",
                textTransform: "initial",
                fontSize: 16,
              },
              ".MuiSvgIcon-root": {
                fontSize: "24px",
              },
            }}
          >
            {regions.map((region, index) => {
              return <Tab key={region} label={region} {...a11yProps(index)} />;
            })}
          </Tabs>
        </Box>
      </Paper>
    </Box>
  );
};

const ExecutivesContent = () => {
  return (
    <Stack sx={{ pb: 6, maxWidth: "100%", pl: { xs: 0, md: 5 } }}>
      <Stack sx={{ maxWidth: { xs: "100%", md: "30%" } }}>
        <Title title={"Regional Executives"} underline xSpace />
      </Stack>
      <ExecutiveSlider executives={executives} />
    </Stack>
  );
};

const ActivitiesContent = () => {
  return (
    <Box sx={{ display: { xs: "flex" }, py: 6, px: { xs: 0, md: 6 } }}>
      <Box flex={1}>
        <Title
          title={"Activities"}
          subTitle={"The objects for which the Association is formed are:"}
          underline
        />
        <CustomStepper steps={steps} textLarger />
      </Box>
    </Box>
  );
};

const MeetingContent = () => {
  return (
    <Box flexDirection={"column"} sx={{ my: 12, position: "relative" }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "20px",
          height: 600,
          display: { xs: "flex" },
          bgcolor: "#EAEAEA",
          flexDirection: { xs: "column" },
        }}
      >
        <Box
          sx={{
            px: { xs: "20px", md: "100px" },
            py: { xs: "30px", md: "50px" },
            position: "absolute",
            top: 0,
            left: 0,
            right: { xs: 0, md: "50%" },
            bottom: { xs: 50, md: 0 },
          }}
        >
          <Title
            title={"Meeting Days"}
            subTitle={placeHolderText.short}
            underline
            xSpace
          />

          <RowText bolden first={"Meeting Day"} second={"Meeting Time"} />
          {meetingDays?.map((m, i) => {
            return (
              <React.Fragment key={i}>
                <RowText first={m.day} second={m.time} />
              </React.Fragment>
            );
          })}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 128,
            left: "50%",
            right: 24,
            bottom: -128,
            backgroundImage: `url(${Meeting})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
          }}
        ></Box>
      </Paper>
    </Box>
  );
};

const ContactsContent = () => {
  return (
    <Box sx={{ display: { xs: "flex" }, my: 24 }}>
      <Box flex={0.1}></Box>
      <Box flex={1}>
        <Title
          title={"Contact Address"}
          subTitle={"To get-in-touch with our secretariat contact us on"}
          underline
        />

        <IconedText
          text={"info@gamls.org"}
          icon={<EmailOutlined fontSize="24px" />}
          noCenter
        />
        <IconedText
          text={"+233 (0) 20 234 4213"}
          icon={<CallOutlinedIcon fontSize="24px" />}
          noCenter
        />

        <Box sx={{ display: "flex", mt: 2 }}>
          <Typography
            variant={"body2"}
            gutterBottom
            sx={{ pl: 1 }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const Content = () => {
  return (
    <Box
      sx={{
        px: { xs: "15px", md: "140px" },
      }}
    >
      <BranchSlider />
      <ExecutivesContent />
      <ActivitiesContent />
      <MeetingContent />
      <ContactsContent />
    </Box>
  );
};
