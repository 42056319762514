import { Box } from "@mui/material";
import Image from "mui-image";
import React from "react";

const location = {
  key: "AIzaSyB_sMFUDRTKnJTD02cpGyWE09v5nFY7258",
};

const Map = ({ url }) => {
  return (
    <Box my={3}>
      <Image src={`${url}&zoom=14&size=400x400&key=${location.key}`} />
    </Box>
  );
};

export default Map;
