import { createSlice } from "@reduxjs/toolkit";
import User from "../models/user";
import { reducers } from "../../constants/appConstants";

const initialState = {
  user: new User(),
  userLearnings: [],
  learningsLoading: false,
};

export const authSlice = createSlice({
  name: reducers.auth,
  initialState,
  reducers: {
    learningRquest: (state, action) => {
      state.learningsLoading = true;
    },
    learningSuccess: (state, action) => {
      state.learningsLoading = false;
      state.userLearnings = action.payload;
    },
    save: (state, action) => {
      state.user = action.payload;
    },
    clear: (state) => {
      state.user = new User();
    },
  },
});

export const { save, clear } = authSlice.actions;

export default authSlice.reducer;
