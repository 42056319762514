import InputText from "../../../components/form/inputText";
import CustomSelect from "../../../components/form/customSelect";

export const WorkDetails = ({ formik }) => {
  return (
    <>
      <CustomSelect
        helperText={formik.errors.academicLevel}
        placeholder={"Academic Level"}
        data={[
          "Cetificate",
          "Diploma",
          "Degree/MLS. D",
          "Masters",
          "PHD Holders",
          "Fellows",
        ]}
        onSelect={(e) => formik.setFieldValue("academicLevel", e)}
        error={
          formik.touched.academicLevel && Boolean(formik.errors.academicLevel)
        }
        defaultValue={formik.values.academicLevel}
      />
      <InputText
        helperText={formik.errors.specialty}
        placeholder={"Work Specialty"}
        name={"specialty"}
        onChange={formik.handleChange}
        error={formik.touched.specialty && Boolean(formik.errors.specialty)}
        value={formik.values.specialty}
      />
      <InputText
        placeholder={"Current Employer"}
        value={formik.values.currentEmployer}
        onChange={formik.handleChange}
        helperText={formik.errors.currentEmployer}
        name={"currentEmployer"}
        error={
          formik.touched.currentEmployer &&
          Boolean(formik.errors.currentEmployer)
        }
      />
      <InputText
        placeholder={"Current Employer's Address"}
        value={formik.values.employerAddress}
        onChange={formik.handleChange}
        helperText={formik.errors.employerAddress}
        name={"employerAddress"}
        error={
          formik.touched.employerAddress &&
          Boolean(formik.errors.employerAddress)
        }
      />

      <InputText
        placeholder={"Staff ID"}
        value={formik.values.staffId}
        onChange={formik.handleChange}
        helperText={formik.errors.staffId}
        name={"staffId"}
        error={formik.touched.staffId && Boolean(formik.errors.staffId)}
      />
    </>
  );
};
