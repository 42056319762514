import { SvgIcon } from "@mui/material";
import * as React from "react";

function ArrowForwardIos(props) {
  return (
    <SvgIcon {...props} height="48" viewBox="0 96 960 960">
      <path d="m561 814-43-42 168-168H160v-60h526L517 375l43-42 241 241-240 240Z" />
    </SvgIcon>
  );
}

export default ArrowForwardIos;
