import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";

const Newsletter = () => {
  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        width: "100%",
        p: { xs: "22px 16px", md: "48px 24px" },
        background:
          "transparent linear-gradient(249deg, #F30813 0%, #7A040A 100%)",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{ color: "white", mb: { xs: 4 } }}
        flex={1}
        justifyContent={"space-between"}
      >
        <Typography variant={"overline"} fontWeight={"bold"} gutterBottom>
          {"GAMLS SUPER MORNINGS"}
        </Typography>
        <Typography variant={"h5"} fontWeight={"bold"} gutterBottom>
          {"Get your daily does of medical laboratory news"}
        </Typography>
        <Typography variant={"body1"}>
          {
            "The best stories in medical laboratory delivered to you right in your inbox"
          }
        </Typography>
      </Box>

      <Box flex={0.1} sx={{ display: { xs: "none", md: "flex" } }}></Box>
      <Box flex={1} sx={{ display: "flex" }} alignItems={"center"}>
        <TextField
          placeholder="Email Address"
          variant="outlined"
          sx={{
            flex: 1,
            bgcolor: "white",
            borderRadius: "10px 0px 0px 10px",
          }}
        />
        <Button
          disableElevation
          variant={"contained"}
          color={"error"}
          sx={{ height: "56px", fontWeight: "bold" }}
        >
          {"Subscribe"}
        </Button>
      </Box>
    </Box>
  );
};

export default Newsletter;
