import { SvgIcon } from "@mui/material";
import { ReactComponent as OutlinedFlagTwoTone } from '../icons/ic_flag.svg';

function OutlinedFlagTwoToneIcon(props){
    return  <SvgIcon
    {...props}
    component={OutlinedFlagTwoTone}
    inheritViewBox
  />
}

export default OutlinedFlagTwoToneIcon