import * as React from "react";
import { Box, Button, Card, CardContent, Stack } from "@mui/material";
import { Markup } from "interweave";
import ProgressIndicator from "./progressIndicator";
import { Hero } from "../assets";
import { useSelector } from "react-redux";

const delay = 5000;
const defaultBanner = [
  {
    id: 1,
    img_url: Hero,
    banner_text:
      "<p><strong>Register for the biggest CPDS with professional learn lessons</strong></p>",
    btn_text: "Register",
    btn_link: `${window.location.href}learning`,
  },
];

export default function BannerSlider() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const { data } = useSelector((state) => state.banner);
  const banners = data.length === 0 ? defaultBanner : data;

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setActiveIndex((prevIndex) =>
          prevIndex === banners?.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [activeIndex, banners?.length]);

  const handleClick = React.useCallback(() => {
    window.open(banners[activeIndex]?.btn_link);
  }, [activeIndex, banners]);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  return (
    <Box
      className="slideshowSlider"
      sx={{
        backgroundImage: `url(${banners[activeIndex]?.img_url})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        width: "100%",
        height: { xs: 344, md: "800px" },
        px: { xs: 3, md: 10 },
        justifyContent: "space-between",
        alignItems: { xs: "center", md: "flex-end" },
        pb: { xs: 0, md: 20 },
        transition: "background-image 1s ease-in-out",
        WebkitTransition: "background-image 1s ease-in-out",
      }}
    >
      <Card
        sx={{
          maxWidth: { xs: "70%", md: "30%", sm: "30%" },
          width: { xs: "70%", md: "30%", sm: "30%" },
          borderRadius: "16px",
          height: { xs: 128, md: 200 },
          fontSize: { xs: 14, md: 24 },
        }}
      >
        <CardContent
          component={Stack}
          justifyContent={"space-between"}
          sx={{ height: "100%", overflow: "hidden" }}
        >
          <Box className="block-ellipsis" style={{ fontWeight: "bold" }}>
            <Markup content={banners[activeIndex]?.banner_text} />
          </Box>

          <Button
            variant={"contained"}
            disableElevation
            onClick={handleClick}
            sx={{
              display: banners[activeIndex]?.btn_text ? "inherit" : "none",
              borderRadius: 5,
              fontSize: { xs: 12, md: 16 },
              textTransform: "initial",
              fontWeight: "bold",
              width: "fit-content",
              height: { xs: 24, md: 32 },
              px: { xs: "inherit", md: 4 },
            }}
          >
            {banners[activeIndex]?.btn_text}
          </Button>
        </CardContent>
      </Card>
      <ProgressIndicator
        count={banners.length}
        activeIndex={activeIndex}
        onClick={(i) => setActiveIndex(i)}
      />
    </Box>
  );
}
