import { Box, Paper, Stack, Typography } from "@mui/material";
import Image from "mui-image";
import React from "react";
import { Background, Meeting } from "../../assets/index";
import { Newsletter, Title } from "../../components";
import CountUp from "react-countup";
import { partners } from "../../constants";

const Card = ({ title = "", desc = "" }) => {
  return (
    <Box flex={2} sx={{ display: "flex" }}>
      <Paper
        sx={{
          flex: 1,
          display: "flex",
          borderRadius: 5,
          p: 3,
          flexDirection: "column",
        }}
      >
        <Title
          title={title}
          underline
          subTitle={title.toLowerCase() !== "values" && desc}
        />
        {title.toLowerCase() === "values" && (
          <ol style={{ padding: "0px 12px", fontSize: 16 }}>
            {[
              "Accountability & Integrity,",
              "Commitment & Efficiency",
              "Innovation",
              "Respect of Clients",
            ].map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ol>
        )}
      </Paper>
    </Box>
  );
};

const FundGradientCard = () => {
  return (
    <Stack sx={{ width: "100%", py: 6 }} alignItems={"center"}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        sx={{
          width: "100%",
          p: { xs: "40px 0px", md: "67px 200px" },
          background:
            "transparent linear-gradient(270deg, #F30813 0%, #7A040A 100%)",
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {[
          { title: "Years", value: 10, prefix: "" },
          { title: "Cedis", value: 200028, prefix: "¢" },
          { title: "Donors", value: 982, prefix: "" },
          { title: "Projects", value: 739, prefix: "" },
        ].map((item) => {
          return (
            <Stack
              flexGrow={1}
              alignItems={{ xs: "center", md: "flex-start" }}
              key={item.title}
            >
              <CountUp
                start={0}
                end={item.value}
                duration={10}
                separator=","
                prefix={item.prefix}
                enableScrollSpy={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span
                      ref={countUpRef}
                      style={{ fontSize: 32, fontWeight: "bold" }}
                    />
                  </div>
                )}
              </CountUp>

              <Typography variant={"h6"}>{item.title}</Typography>
            </Stack>
          );
        })}
      </Stack>
      <Box
        sx={{
          width: 0,
          height: 0,
          borderLeft: "50px solid transparent",
          borderRight: "50px solid transparent",
          borderTop: "50px solid rgb(167,36,29)",
        }}
      ></Box>
    </Stack>
  );
};

export const Content = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          mx: { xs: "15px", md: "80px" },
          py: { xs: 6, md: 12 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          flexGrow={1}
          sx={{ mb: { xs: 2, md: 12 } }}
        >
          <Box flex={1}>
            <Stack>
              <Typography color={"primary.main"} fontWeight={"bold"}>
                {"Get to What The Fund Does"}
              </Typography>
              <Title title={"About The GAMLS Fund"} underline />
            </Stack>

            <Box flex={1} sx={{ display: { xs: "inherit", md: "none" } }}>
              <Image src={Meeting} />
            </Box>

            <Typography gutterBottom mt={{ xs: 3 }} mb={3}>
              {
                "This is a body corporate registered under the laws of Ghana having its corporate office at the National Secretariat of GAMLS, Korle-Bu near the SBAHS Library. The GAMLS Fund seeks to create a provident fund for its members and to serve as supplementary income during retirement."
              }
            </Typography>

            <Typography>
              {"Fund Manager: Medical Laboratory Cooperative Credit Union"}
            </Typography>
          </Box>
          <Box flex={1} sx={{ display: { xs: "none", md: "inherit" } }}>
            <Image src={Meeting} />
          </Box>
        </Stack>

        <FundGradientCard />

        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{ width: "100%" }}
          spacing={4}
          mb={{ md: 12, xs: 4 }}
        >
          {[
            {
              title: "Mission",
              desc: "To provide a comfortable and dependable retirement for its members through judicious investment strategy.",
            },
            {
              title: "Vision",
              desc: "To be the most Reliable and trusted income for all Medical Laboratory Science professionals in employment and retirement.",
            },
            {
              title: "Values",
              desc: "To provide a comfortable and dependable retirement for its members through judicious investment strategy.",
            },
          ].map((item) => {
            return (
              <React.Fragment key={item.title}>
                <Card title={item.title} desc={item.desc} />
              </React.Fragment>
            );
          })}
        </Stack>

        <Title title={"Our Corporate Partners"} underline alignHead/>
        <Stack
          direction={"row"}
          flexGrow={1}
          sx={{
            overflow: "hidden",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
          }}
          mb={6}
        >
          {partners.map((src, i) => {
            return (
              <img
                src={src}
                alt={"partner logo"}
                key={i}
                height={50}
                style={{ marginTop: "10px" }}
                loading={"lazy"}
              />
            );
          })}
        </Stack>

        <Newsletter />
      </Box>
    </Box>
  );
};
