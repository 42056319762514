import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import Swal from "sweetalert2";
import { mapAuthCodeToMessage } from "../utils/mapCodeToMessage";
import { clear, save } from "./auth/authSlice";

const firebaseConfig = {
  apiKey: "AIzaSyBVr-Bc59UddLW2LPnMW-e5DwABFo923d4",
  authDomain: "gamls-90281.firebaseapp.com",
  databaseURL: "https://gamls-90281-default-rtdb.firebaseio.com",
  projectId: "gamls-90281",
  storageBucket: "gamls-90281.appspot.com",
  messagingSenderId: "805142931162",
  appId: "1:805142931162:web:b14eb4ae576506cc4da7e3",
  measurementId: "G-HQ1SVZ1RPW",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const registerWithEmailAndPassword = async (email, password, data) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      ...data,
      date: serverTimestamp(),
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Registration unsuccessful",
      text: mapAuthCodeToMessage(err.code),
      confirmButtonColor: "#000000",
    });
  }
};

const sendPasswordReset = async (email, navigate) => {
  try {
    await sendPasswordResetEmail(auth, email);
    Swal.fire({
      title: "Success",
      text: "Password reset link sent!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Password reset error",
      text: mapAuthCodeToMessage(err.code),
      confirmButtonColor: "#000000",
    });
  }
};

const logout = (dispatch) => {
  signOut(auth)
    .then(() => {
      dispatch(clear());
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Logout error",
        text: mapAuthCodeToMessage(error.code),
        confirmButtonColor: "#000000",
      });
    });
};

const getUser = async (dispatch) => {
  try {
    const docRef = doc(db, `users/${auth.currentUser.uid}`);
    const unsub = onSnapshot(docRef, (doc) => {
      dispatch(save(doc.data()));
    });
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Action could not be completed. Kindly retry",
      confirmButtonColor: "#000000",
    });
  }
};

export {
  auth,
  db,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUser,
  storage,
};
