import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Background, HistoryBanner } from "../../assets/index";
import LearningCard from "../../components/learningCard";
import { useGreeting } from "../../hooks/useGreeting";
import {
  clearCoursLessons,
  fetchCategories,
} from "../../data/learning/learningAction";
import empty from "../../assets/json/empty.json";
import Lottie from "lottie-react";

const options = [
  { name: "Mathematics", value: "mathematics" },
  { name: "Science", value: "science" },
  { name: "English", value: "english" },
];

const LearningBanner = () => {
  const greetingMessage = useGreeting();
  return (
    <Box
      sx={{
        backgroundImage: `url(${HistoryBanner})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        color: "white",
        overflow: "hidden",
      }}
    >
      <Stack
        spacing={{ xs: 2, md: 3 }}
        sx={{
          background:
            "transparent linear-gradient(95deg, #F3081340 0%, #7A040AFA 100%) 0% 0% no-repeat padding-box",
          p: { xs: "56px 15px", md: "40px 80px" },
        }}
      >
        <Typography fontWeight={"bold"} sx={{ fontSize: { xs: 18, md: 32 } }}>
          {greetingMessage}
        </Typography>

        <Typography sx={{ fontSize: { xs: 12, md: 18 } }}>
          In Medical Laboratory we count it a privilege to do everything we can
          to sit our medical colleagues <br /> in the application of these new
          tools to the problems of human suffering."
        </Typography>

        <Typography variant={"body2"} fontWeight={"bold"}>
          {" — Ernest Lawrence (American Scientist)"}
        </Typography>
      </Stack>
    </Box>
  );
};

function SelectInput({ label = "", options = [] }) {
  return (
    <Box>
      <FormControl>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {label}
        </InputLabel>
        <NativeSelect
          inputProps={{
            name: label?.toLowerCase(),
            id: "uncontrolled-native",
          }}
          placeholder={"Select"}
        >
          {options?.map((option, idx) => (
            <option key={idx} value={option?.value}>
              {option.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
}

const Content = () => {
  const dispatch = useDispatch();
  const { data: categories, loading } = useSelector(
    (state) => state.categories
  );
  const { lessons } = useSelector((state) => state.lessons);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (lessons.length === 0) return;
    dispatch(clearCoursLessons());
  }, [lessons, dispatch]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          px: { xs: "15px", md: "80px" },
          py: { xs: 2, md: 12 },
        }}
        spacing={6}
      >
        {!loading && categories.length === 0 ? (
          <Lottie animationData={empty} />
        ) : (
          <>
            <Typography
              variant={"h5"}
              fontWeight={"bold"}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              CPD's available for you
            </Typography>
            <Stack
              direction={"row"}
              flexGrow={1}
              spacing={{ xs: 3, md: 6 }}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <SelectInput label={"Categories"} options={options} />
              <SelectInput label={"Course"} options={options} />
              <Button>Clear</Button>
            </Stack>
            <Grid
              container
              rowSpacing={{ xs: 2, md: 6 }}
              columnSpacing={{ xs: 0, md: 4, sm: 8 }}
            >
              {(loading ? Array.from(new Array(4)) : categories)?.map(
                (lesson, idx) => {
                  return (
                    <Grid
                      item
                      container
                      key={lesson ? lesson.id : idx}
                      xs={12}
                      md={4}
                      lg={3}
                      sm={5}
                    >
                      {lesson ? (
                        <LearningCard data={lesson} />
                      ) : (
                        <Card
                          elevation={0}
                          sx={{
                            borderRadius: "20px",
                            height: "400px",
                            width: { xs: "100%", md: 300 },
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            }
                            title={
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                              />
                            }
                            subheader={
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="40%"
                              />
                            }
                          />
                          <Skeleton
                            sx={{ height: 250 }}
                            animation="wave"
                            variant="rectangular"
                          />
                          <CardContent>
                            <React.Fragment>
                              <Skeleton
                                animation="wave"
                                height={10}
                                style={{ marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                              />
                            </React.Fragment>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  );
                }
              )}
            </Grid>
          </>
        )}
      </Stack>
    </Box>
  );
};

export { LearningBanner, SelectInput, Content };
