import { Container, Stack, Typography, Box, Button } from "@mui/material";
import { NoData, UnderConstructionImg } from "../assets";

const UnderConstruction = ({ error }) => {
  return (
    <Container sx={{ py: { xs: 6, md: 8 } }}>
      <Stack spacing={2} m={1}>
        <Typography
          variant={"h3"}
          textAlign={"center"}
          color={"primary"}
          fontWeight={"bold"}
        >
          {error ? "Sorry!" : "Oops!"}
        </Typography>
        <Typography variant={"h5"} textAlign={"center"} color={"primary"}>
          {error
            ? "Something happened while showing this page"
            : "Page under construction"}
        </Typography>
        <Typography variant={"body1"} textAlign={"center"} color={"primary"}>
          {error
            ? "Click on the button below to refresh the page"
            : "Try in a while, we are working on something beautiful for you"}
        </Typography>
      </Stack>
      <Stack flex={1} spacing={3}>
        <Box
          component={"img"}
          src={error ? NoData : UnderConstructionImg}
          height={500}
          loading={"lazy"}
        />
        {error && (
          <Button onClick={() => (window.location.href = "/")}>
            {"Refesh Page"}
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default UnderConstruction;
